import axios from 'axios'
//创建axios的一个实例 
// http://28e55o6211.qicp.vip/
// import router from '../router'
// const baseURL = process.env.NODE_ENV == 'development' ? 'http://api.fushangma.cn:8081/' : 'http://api.fushangma.cn/';
const baseURL = process.env.NODE_ENV == 'development' ? 'https://api.fushangma.cn/' : 'https://api.fushangma.cn/';
import {
    message
} from "ant-design-vue";

// export default baseURL
var instance = axios.create({
    // baseURL: 'https://fm.childjoy.cn/',
    baseURL: baseURL, //接口统一域名
    timeout: 60000 //设置超时
})
// instance.defaults.headers['Access-Control-Allow-Origin'] = "*";
instance.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
// instance.defaults.timeout = 6000
//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(function (config) {
    // if (localStorage.getItem("token")) {
    //     config.headers['X-Token'] = localStorage.getItem("token")
    //     if (localStorage.getItem("id")) {
    //         config.headers['X-Store'] = localStorage.getItem("id")
    //     }
    // }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(function (response) {
    if (response.data.status) {
        return response.data
    } else {
        message.error(response.data.msg)
        return false;
    }
    if (response.data.err_code === 0) {
        return response.data;
    } else if (response.data.err_code === 10000) {

    } else {

        console.log('123')
        console.log(response.msg)
        message.error(response.msg)
        // return false
        return false;
    }
}, function (error) {
    console.log(error)
    // 对响应错误做点什么
    console.log('拦截器报错 ');
    // if(error.response.status == 500){
    //   Message.error("服务器错误，请联系管理员！");
    // }
    return Promise.reject(error.response);
});
export default {
    instance,
    baseURL
}